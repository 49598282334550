.footer-container {
  background-color: #262626;
  scroll-snap-align: end;
}

.footer-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  flex-direction: column;
}

.footer-actions {
  display: flex;
  margin-top: 2rem;
}

.footer-actions a {
  /* Contact */

  font-family: "Cera Pro";
  font-style: normal;
  font-weight: var(--anchor-link-font-weight);
  font-size: var(--anchor-link-font-size);
  line-height: 14px;
  /* identical to box height, or 88% */
  text-align: center;
  padding: 2rem;
  color: #ffffff;
}

.line-seperator {
  border: 1px solid #dcdcdc33;
  margin: 1rem 0;
}

.footer-subactions {
  display: flex;
  justify-content: space-between;
}

.footer-subactions a {
  font-family: "Cera Pro";
  font-style: normal;
  font-weight: var(--anchor-link-font-weight);
  font-size: var(--anchor-link-font-size);
  line-height: 14px;
  /* identical to box height, or 88% */
  text-align: center;
  padding: 2rem;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .footer-actions {
    flex-direction: column;
    align-items: center;
  }

  .footer-subactions a {
    font-size: 15px !important;
    line-height: 20px;
    padding: 1rem !important;
    padding-top: 0px !important;
    padding-bottom: 24px !important;
  }

  .footer-actions a {
    padding: 1.5rem;
    font-size: 16px !important;
  }

  #footer-logo-id {
    height: 16vw;
    margin-left: -2rem;
  }

  .footer-body {
    padding: 1.7rem;
    padding-bottom: 0px;
  }

  /* .footer-container {
    margin-bottom: -10px;
  } */

  /* .footer-container {
    margin-bottom: -10px;
  } */
}