.investment-wrapper {
  width: 100%;
  height: 108vh;
  overflow: auto;
  display: flex;
  position: relative;
  /* scroll-snap-type: y none; */
  flex-direction: column;
}

.investment-wrapper::-webkit-scrollbar {
  width: 0;
}

.investment-container {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  scroll-snap-align: start;
}

.investment-image-div {
  height: calc(55vh - var(--header-height));
  width: 100%;
  position: relative;
  margin-left: var(--side-margin);
  margin-right: var(--side-margin);
  margin-top: var(--header-height);
}

.investment-bg {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  /* height: calc(100% - var(--header-height)); */
  position: absolute;
}

.investment-title {
  margin-left: var(--side-margin);
  margin-right: var(--side-margin);
  color: #203468;

  font-family: Wensley;
  font-size: var(--header-font-size);
  font-style: normal;
  font-weight: var(--title-font-weight);
  line-height: var(--header-font-size);
  /* 100% */
  letter-spacing: -8.8px;
  text-transform: uppercase;
  margin-top: 25px;
}

.investment-desc {
  font-family: "Cera Pro";
  font-style: normal;
  font-weight: var(--title-font-weight);
  font-size: var(--font-size-small);
  text-transform: capitalize;

  color: #3b3b3b;
  /* scroll-snap-align: end; */
  margin: auto;
  max-width: 739px;
  /* margin-top: 35px;
    margin-bottom: 156px; */
}

.snap-point-end {
  scroll-snap-align: end;
  height: 156px;
}

.investment-learn-more {
  font-family: "Cera Pro";
  font-style: normal;
  font-weight: var(--anchor-link-font-weight);
  font-size: var(--anchor-link-font-size);
  line-height: 14px;
  /* identical to box height, or 88% */
  text-decoration-line: underline;
  text-transform: uppercase;

  color: #203468;
}

.bg-text {
  /* Investment Approach */

  font-family: "Wensley";
  font-style: normal;
  font-weight: var(--title-font-weight);
  font-size: var(--title-font-size);
  line-height: 40px;
  /* or 111% */
  text-align: center;
  letter-spacing: -0.06em;
  color: #ffffff;
  text-transform: uppercase;
}

/* mobile */

@media (max-width: 768px) {
  .investment-title {
    letter-spacing: -4px;
  }

  .investment-image-div {
    height: 275px;
  }

  .investment-bg {
    height: 270px;
  }

  .snap-point-end {
    scroll-snap-align: end;
    height: 56px;
  }

  .investment-container {
    height: 43vh;
  }

  .investment-desc {
    margin: 0px;
  }
}


/* mobile investemet */


.investment-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
}

.investment-image-div {
  background-size: cover;
  background-position: center;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 32px;
  font-weight: bold;
  padding: 20px;
}

.bg-text {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  color: white;
}

.investment-title {
  font-size: 28px;
  font-weight: 700;
  color: #001F5F;
  margin-bottom: 10px;
}

.bg-cyan {
  color: #4EC3F7;
}

.investment-desc {
  font-size: 13.5px;
  line-height: 1.6;
  color: #333;
}



.investment-learn-more:hover {
  text-decoration: underline;
}

.web-view-investment-wrapper {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
}

.investment-title.paragraph-gap.web-view-title-investment {
  letter-spacing: -5.2px;
  font-size: 70px;
  font-family: 'Wensley', sans-serif;
  margin-top: 39px;
  font-weight: 400;
}

/* Responsive Design */
@media (max-width: 768px) {
  .investment-wrapper {
    flex-direction: column;
    text-align: center;
  }

  .investment-col-left {
    height: 211px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  p.investment-bg-text {
    font-size: 36px;
    font-family: 'Wensley';
    line-height: 33px;
    color: white;
    letter-spacing: -2.5px;
  }

  .investment-image-div {
    width: 100%;
    min-height: 300px;
  }

  .investment-description {
    padding: 16px;
    padding-bottom: 59px;
  }

  .investment-learn-more {
    position: relative;
    top: 5px;
  }

  .investment-containers {
    margin-top: 5rem;
  }

  .investment-title {
    font-size: 40px;
    font-weight: 400;
    letter-spacing: -3px;
  }
}