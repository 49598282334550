@font-face {
  font-family: "Cera Pro";
  src: url("./assets/fonts/CeraPro/CeraPro-Light.eot");
  src: local("Cera Pro Light"), local("./assets/fonts/CeraPro/CeraPro-Light"),
    url("./assets/fonts/CeraPro/CeraPro-Light.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/CeraPro/CeraPro-Light.woff2") format("woff2"),
    url("./assets/fonts/CeraPro/CeraPro-Light.woff") format("woff"),
    url("./assets/fonts/CeraPro/CeraPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./assets/fonts/CeraPro/CeraPro-MediumItalic.eot");
  src: local("Cera Pro Medium Italic"),
    local("./assets/fonts/CeraPro/CeraPro-MediumItalic"),
    url("./assets/fonts/CeraPro/CeraPro-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/CeraPro/CeraPro-MediumItalic.woff2") format("woff2"),
    url("./assets/fonts/CeraPro/CeraPro-MediumItalic.woff") format("woff"),
    url("./assets/fonts/CeraPro/CeraPro-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./assets/fonts/CeraPro/CeraPro-RegularItalic.eot");
  src: local("Cera Pro Regular Italic"),
    local("./assets/fonts/CeraPro/CeraPro-RegularItalic"),
    url("./assets/fonts/CeraPro/CeraPro-RegularItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/CeraPro/CeraPro-RegularItalic.woff2") format("woff2"),
    url("./assets/fonts/CeraPro/CeraPro-RegularItalic.woff") format("woff"),
    url("./assets/fonts/CeraPro/CeraPro-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./assets/fonts/CeraPro/CeraPro-BlackItalic.eot");
  src: local("Cera Pro Black Italic"),
    local("./assets/fonts/CeraPro/CeraPro-BlackItalic"),
    url("./assets/fonts/CeraPro/CeraPro-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/CeraPro/CeraPro-BlackItalic.woff2") format("woff2"),
    url("./assets/fonts/CeraPro/CeraPro-BlackItalic.woff") format("woff"),
    url("./assets/fonts/CeraPro/CeraPro-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./assets/fonts/CeraPro/CeraPro-Medium.eot");
  src: local("Cera Pro Medium"), local("./assets/fonts/CeraPro/CeraPro-Medium"),
    url("./assets/fonts/CeraPro/CeraPro-Medium.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/CeraPro/CeraPro-Medium.woff2") format("woff2"),
    url("./assets/fonts/CeraPro/CeraPro-Medium.woff") format("woff"),
    url("./assets/fonts/CeraPro/CeraPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./assets/fonts/CeraPro/CeraPro-BoldItalic.eot");
  src: local("Cera Pro Bold Italic"),
    local("./assets/fonts/CeraPro/CeraPro-BoldItalic"),
    url("./assets/fonts/CeraPro/CeraPro-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/CeraPro/CeraPro-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/CeraPro/CeraPro-BoldItalic.woff") format("woff"),
    url("./assets/fonts/CeraPro/CeraPro-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./assets/fonts/CeraPro/CeraPro-ThinItalic.eot");
  src: local("Cera Pro Thin Italic"),
    local("./assets/fonts/CeraPro/CeraPro-ThinItalic"),
    url("./assets/fonts/CeraPro/CeraPro-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/CeraPro/CeraPro-ThinItalic.woff2") format("woff2"),
    url("./assets/fonts/CeraPro/CeraPro-ThinItalic.woff") format("woff"),
    url("./assets/fonts/CeraPro/CeraPro-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./assets/fonts/CeraPro/CeraPro-LightItalic.eot");
  src: local("Cera Pro Light Italic"),
    local("./assets/fonts/CeraPro/CeraPro-LightItalic"),
    url("./assets/fonts/CeraPro/CeraPro-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/CeraPro/CeraPro-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/CeraPro/CeraPro-LightItalic.woff") format("woff"),
    url("./assets/fonts/CeraPro/CeraPro-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./assets/fonts/CeraPro/CeraPro-Black.eot");
  src: local("Cera Pro Black"), local("./assets/fonts/CeraPro/CeraPro-Black"),
    url("./assets/fonts/CeraPro/CeraPro-Black.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/CeraPro/CeraPro-Black.woff2") format("woff2"),
    url("./assets/fonts/CeraPro/CeraPro-Black.woff") format("woff"),
    url("./assets/fonts/CeraPro/CeraPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./assets/fonts/CeraPro/CeraPro-Bold.eot");
  src: local("Cera Pro Bold"), local("./assets/fonts/CeraPro/CeraPro-Bold"),
    url("./assets/fonts/CeraPro/CeraPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/CeraPro/CeraPro-Bold.woff2") format("woff2"),
    url("./assets/fonts/CeraPro/CeraPro-Bold.woff") format("woff"),
    url("./assets/fonts/CeraPro/CeraPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./assets/fonts/CeraPro/CeraPro-Regular.eot");
  src: local("Cera Pro Regular"),
    local("./assets/fonts/CeraPro/CeraPro-Regular"),
    url("./assets/fonts/CeraPro/CeraPro-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/CeraPro/CeraPro-Regular.woff2") format("woff2"),
    url("./assets/fonts/CeraPro/CeraPro-Regular.woff") format("woff"),
    url("./assets/fonts/CeraPro/CeraPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("./assets/fonts/CeraPro/CeraPro-Thin.eot");
  src: local("Cera Pro Thin"), local("./assets/fonts/CeraPro/CeraPro-Thin"),
    url("./assets/fonts/CeraPro/CeraPro-Thin.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/CeraPro/CeraPro-Thin.woff2") format("woff2"),
    url("./assets/fonts/CeraPro/CeraPro-Thin.woff") format("woff"),
    url("./assets/fonts/CeraPro/CeraPro-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Wensley" !important;
}

/* fonts custom sizes */
.dv-fs-10 {
  font-size: 10px;
}

.dv-fs-11 {
  font-size: 11px;
}

.dv-fs-12 {
  font-size: 12px;
}

.dv-fs-13 {
  font-size: 13px;
}

.dv-fs-14 {
  font-size: 14px;
}

.dv-fs-15 {
  font-size: 15px;
}

.dv-fs-16 {
  font-size: 16px;
}

.dv-fs-17 {
  font-size: 17px;
}

.dv-fs-18 {
  font-size: 18px;
}

.dv-fs-19 {
  font-size: 19px;
}

.dv-fs-20 {
  font-size: 20px;
}

.dv-fs-21 {
  font-size: 21px;
}

.dv-fs-22 {
  font-size: 22px;
}

.dv-fs-23 {
  font-size: 23px;
}

.dv-fs-24 {
  font-size: 24px;
}

.dv-fs-25 {
  font-size: 25px;
}

.dv-fs-26 {
  font-size: 26px;
}

.dv-fs-27 {
  font-size: 27px;
}

.dv-fs-28 {
  font-size: 28px;
}

.dv-fs-29 {
  font-size: 29px;
}

.dv-fs-30 {
  font-size: 30px;
}

.dv-fs-31 {
  font-size: 31px;
}

.dv-fs-32 {
  font-size: 32px;
}

.dv-fs-33 {
  font-size: 33px;
}

.dv-fs-34 {
  font-size: 34px;
}

.dv-fs-35 {
  font-size: 35px;
}

.dv-fs-36 {
  font-size: 36px;
}

.dv-fs-37 {
  font-size: 37px;
}

.dv-fs-38 {
  font-size: 38px;
}

.dv-fs-39 {
  font-size: 39px;
}

.dv-fs-40 {
  font-size: 40px;
}

.dv-fs-41 {
  font-size: 41px;
}

.dv-fs-42 {
  font-size: 42px;
}

.dv-fs-43 {
  font-size: 43px;
}

.dv-fs-44 {
  font-size: 44px;
}

.dv-fs-45 {
  font-size: 45px;
}

.dv-fs-46 {
  font-size: 46px;
}

.dv-fs-47 {
  font-size: 47px;
}

.dv-fs-48 {
  font-size: 48px;
}

.dv-fs-49 {
  font-size: 49px;
}

.dv-fs-50 {
  font-size: 50px;
}

.dv-fs-51 {
  font-size: 51px;
}

.dv-fs-52 {
  font-size: 52px;
}

.dv-fs-53 {
  font-size: 53px;
}

.dv-fs-54 {
  font-size: 54px;
}

.dv-fs-55 {
  font-size: 55px;
}

.dv-fs-70 {
  font-size: 70px;
}

.dv-fs-56 {
  font-size: 56px;
}

.dv-fs-57 {
  font-size: 57px;
}

.dv-fs-58 {
  font-size: 58px;
}

.dv-fs-59 {
  font-size: 59px;
}

.dv-fs-60 {
  font-size: 60px;
}

.dv-text-uppercase {
  text-transform: uppercase;
}

.dv-text-primary {
  color: #33b9ea !important;
}

.dss-fw-100 {
  font-weight: 100 !important;
}

.dss-fw-200 {
  font-weight: 200 !important;
}

.dss-fw-300 {
  font-weight: 300 !important;
}

.dss-fw-400 {
  font-weight: 400 !important;
}

.dss-fw-500 {
  font-weight: 500 !important;
}

.dss-fw-600 {
  font-weight: 600 !important;
}

.dss-fw-700 {
  font-weight: 700 !important;
}

.dss-fw-800 {
  font-weight: 800 !important;
}

.dss-fw-900 {
  font-weight: 900 !important;
}

/* end  */

.text-white {
  color: #fff;
}

.text-grey {
  color: #f9f9f9;
}

.paragraph-gap {
  margin-bottom: var(--paragraph-gap);
  line-height: 20px;
}

.progress-bar {
  position: fixed;
  top: 0;
  right: 0;
  width: 10px;
  background: rgb(162 151 129);
  transform-origin: 0%;
  z-index: 21;
}

@media screen and (max-width: 768px) {
  .card-overlay {
    display: none !important;
  }

  .card.principal-card.h-100.border-0.shadow.aos-init.aos-animate {
    width: 100% !important;
  }

  .core-value-box h5 {
    color: #fff;
    font-family: Wensley !important;
    font-size: 25px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -2.16px !important;
  }

  .mb-4.card-div-t {
    width: 100% !important;
    flex-basis: 94% !important;
    margin: auto;
    padding-left: 10px;
  }

  .coming-soon {
    text-align: center !important;
    font-family: 'Wensley';
  }

  h2.mb-4.dv-fs-55.dss-fw-400.dv-md-fs-25.aos-init {
    letter-spacing: -1.8px !important;
  }

  .card-overlay {
    position: absolute;
    display: none;
  }

  .home-container {
    /* scroll-snap-type:none; */
    width: 100%;
    height: 100vh;
    overflow: auto;
  }

  .card-top-dss p {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 21px !important;
  }

  .mobile-card-overlay p {
    display: block;
    margin: 2px;
    font-size: 16px !important;
  }

  .proven-leadership p {
    color: #3b3b3b;

    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
  }

  .content-wrapper {
    text-align: start !important;
    padding: 20px;

    padding: 20px;
    padding-left: 1px;
    padding-right: 28px;
  }

  .main-title {
    color: #2c3e50;
    font-family: Wensley, sans-serif;
    font-size: 34px !important;
    font-weight: 400 !important;
    line-height: 1.1;
    margin-bottom: 1rem;
  }

  .principal-sections h2 {
    color: #203468;
    font-family: Wensley, sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -2.5px;
    line-height: 46px;
    padding-bottom: 20px;
  }

  .header-container {
    width: 100%;
    background: #fff;
    height: var(--header-height);
    flex-shrink: 0;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    z-index: 100;
    gap: 22px;
  }

  .dv-md-fs-25 {
    font-size: 25px !important;
  }

  .main-leadership {
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px !important;
  }

  .card-overlay h4 {
    margin: 10px 0;
    font-size: 20px;
    line-height: 40px !important;
  }

  .card-overlay p {
    margin: 5px 0;
    color: #fff;
    font-family: "Cera Pro";
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 21px !important;
    text-align: left;
  }

  .card-counter {
    padding-top: 4rem !important;
  }

  .p {
    color: #3b3b3b;
    font-family: "Cera Pro";
    font-size: 14px !important;
  }

  .section-title {
    font-size: 1rem !important;
    color: #6c757d;
    margin-bottom: 1rem !important;
    text-align: left !important;
  }

  /* .proven-leadership h2 {
    color: #203468;
    font-family: "Wensley";
    font-size: 32px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 27px !important;
    letter-spacing: -4px !important;
    margin-top: 1rem !important;
  } */

  .card-overlay {
    padding: 31px !important;
  }

  .ml-96 {
    margin-left: 0rem !important;
  }

  .mobile-view-div {
    scroll-snap-type: none;
    width: 100%;
    height: 100vh;
    overflow: auto;
    padding-top: var(--header-height);
    overflow-x: hidden;
  }

  .about-container {
    background: #eaf5fd;
    padding-top: 11rem !important;
    padding: 8rem 1rem;
  }

  .about-content {
    padding: 0 1rem !important;
  }

  p {

    font-family: 'Cera Pro';
    line-height: 20px;
  }

  .about-bg {
    padding: 10px;
  }

  .terms-section.privacy-section {
    background-color: #f8f9fa;
    background-size: cover;
    background-repeat: no-repeat;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: flex-start !important;
    text-align: center;
    padding-top: 130px !important;
  }

  .culture-container-content {
    flex-direction: column;
    padding: 0 1rem;
    gap: 10px !important;
    position: relative;
    z-index: 999999;
  }

  .culture-title {
    text-align: left !important;
  }

  .leadership-bg {
    padding: 0 1rem !important;
    position: relative;
  }

  .leadership-desc {
    width: 100% !important;
    padding: 0 0rem;
  }

  .culture-desc {
    width: 100% !important;
    padding: 0 0rem;
  }

  .investment-desc {
    width: 100% !important;
    padding: 0 1rem;
  }

  .culture-image-div {
    width: 100% !important;
  }

  .progress-bar {
    display: none;
  }

  .sidemenu-container .menu-item {
    padding-left: 1rem;
  }

  .sidemenu-container .copywrite-text {
    padding-left: 1rem;
  }

  .culture-container-snap-wrapper {
    height: unset !important;
  }
}

/* 
  ##Device = Desktop large
  ##Screen = 1441px to higher resolution desktops
*/
@media (min-width: 1441px) {
  :root {
    --paragraph-gap: 1.875rem;
    --header-height: 6.875rem;
    --white: #ffffff;
    --logo-top-gap: 20px;
    --logo-top-gap-home: 70px;
    --logo-height: 65px;
    --logo-main-height: 15vw;
    --side-margin: 10.188rem;
    --title-font-size: 2.25rem;
    --title-font-weight: 400;

    --header-font-size: 6.875rem;

    --font-size-small: 1.125rem;

    --anchor-link-font-weight: 500;
    --anchor-link-font-size: 1rem;
    --anchor-link-line-height: 0.875rem;
    --side-drawer-width: 47.875rem;
    --about-us-counter-div-height: 200px;
  }
}

/* 
  ##Device = Desktops small
  ##Screen = B/w 1281px to 1441px
*/
@media (min-width: 1200px) and (max-width: 4000px) {

  .core-value-container {
    width: 90% !important;
    background-color: #fff;
    margin: auto;
  }

  img.w-full.blog-image-detail {
    height: 474px !important;
    object-fit: cover;
  }

  .px-4.mx-auto.main-section-news {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }

  .main-section-blog-detail {
    padding-left: 80px;
    padding-right: 80px;
  }

  li.breadcrumb-item.breadcrumb-link.px-2.py-1.border-dashed.text-primary.active {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 417px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px !important;
    font-family: 'Cera Pro';
  }



  .about-value-prepotion {
    display: flex;
    color: white;
    margin-top: 2rem;
    gap: 25px;
    align-items: center;
  }

  .about-value-prepotion h2 {

    font-size: 24px !important;

  }

  .boxes-values {
    padding-left: 12px !important;
  }

  .box-value-icon {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-overlay {
    padding: 82px 82px;
    box-sizing: border-box;
    text-align: center;
  }

  .leadership-content {
    padding-right: 6rem !important;
  }

  .popup-close-box h2 {
    font-family: "Wensley" !important;
    font-size: 48px !important;
    font-weight: 700;
    color: #33b9ea !important;
  }

  .popup-close-box .MuiPaper-root {
    max-width: 690px !important;
    padding: 60px 79px !important;
  }
}

@media (min-width: 1281px) and (max-width: 1441px) {
  .card-overlay {
    padding: 82px 82px;
    box-sizing: border-box;
    text-align: center;
  }

  .section-title {
    font-size: 1rem;
    color: #6c757d;
    margin-bottom: 1rem;
    color: #333 !important;
    font-family: "Wensley", sans-serif !important;
    font-size: 36px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 14px !important;
    margin-top: 2rem !important;
    letter-spacing: -1.6px;
  }



  :root {
    --paragraph-gap: 0.875rem;
    --header-height: 6.875rem;
    --white: #ffffff;
    --logo-top-gap: 20px;
    --logo-top-gap-home: 70px;
    --logo-height: 65px;
    --logo-main-height: 14vw;
    --side-margin: 5.671rem;
    --title-font-size: 2.25rem;
    --title-font-weight: 400;

    --header-font-size: 5.156rem;

    --font-size-small: 0.844rem;

    --anchor-link-font-weight: 500;
    --anchor-link-font-size: 0.75rem;
    --anchor-link-line-height: 0.875rem;
    --side-drawer-width: 47.875rem;
    --about-us-counter-div-height: 150px;
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  :root {
    --paragraph-gap: 0.875rem;
    --header-height: 6.875rem;
    --white: #ffffff;
    --logo-top-gap: 20px;
    --logo-top-gap-home: 70px;
    --logo-height: 65px;
    --logo-main-height: 12vw;
    --side-margin: 5.671rem;
    --title-font-size: 2.25rem;
    --title-font-weight: 400;

    --header-font-size: 5.156rem;

    --font-size-small: 0.844rem;

    --anchor-link-font-weight: 500;
    --anchor-link-font-size: 0.75rem;
    --anchor-link-line-height: 0.875rem;
    --side-drawer-width: 47.875rem;
    --about-us-counter-div-height: 150px;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 200px) and (max-width: 1024px) {
  .dss-md-none {
    display: none;
  }

  .dss-p-fs-14 {
    font-size: 16px !important;
  }

  .section-title {
    letter-spacing: normal !important;
  }

  p.lg-none.mt-2.pt-2 {
    font-size: 12px;
  }

  /* p {
    font-size: 12px !important;
  } */

  :root {
    --paragraph-gap: 0.875rem;
    --header-height: 6.875rem;
    --white: #ffffff;
    --logo-top-gap: 20px;
    --logo-top-gap-home: 70px;
    --logo-height: 65px;
    --logo-main-height: 12vw;
    --side-margin: 5.671rem;
    --title-font-size: 2.25rem;
    --title-font-weight: 400;

    --header-font-size: 5.156rem;

    --font-size-small: 0.844rem;

    --anchor-link-font-weight: 500;
    --anchor-link-font-size: 0.75rem;
    --anchor-link-line-height: 0.875rem;
    --side-drawer-width: 47.875rem;
    --about-us-counter-div-height: 150px;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  :root {
    --paragraph-gap: 0.875rem;
    --header-height: 6.875rem;
    --white: #ffffff;
    --logo-top-gap: 20px;
    --logo-top-gap-home: 70px;
    --logo-height: 65px;
    --logo-main-height: 12vw;
    --side-margin: 5.671rem;
    --title-font-size: 2.25rem;
    --title-font-weight: 400;

    --header-font-size: 5.156rem;

    --font-size-small: 0.844rem;

    --anchor-link-font-weight: 500;
    --anchor-link-font-size: 0.75rem;
    --anchor-link-line-height: 0.875rem;
    --side-drawer-width: 47.875rem;
    --about-us-counter-div-height: 150px;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  :root {
    --paragraph-gap: 0.875rem;
    --header-height: 6.875rem;
    --white: #ffffff;
    --logo-top-gap: 20px;
    --logo-top-gap-home: 70px;
    --logo-height: 65px;
    --logo-main-height: 12vw;
    --side-margin: 5.671rem;
    --title-font-size: 2.25rem;
    --title-font-weight: 400;

    --header-font-size: 5.156rem;

    --font-size-small: 0.844rem;

    --anchor-link-font-weight: 500;
    --anchor-link-font-size: 0.75rem;
    --anchor-link-line-height: 0.875rem;
    --side-drawer-width: 90%;
    --about-us-counter-div-height: 150px;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  :root {
    --paragraph-gap: 0.875rem;
    --header-height: 4.875rem;
    --white: #ffffff;
    --logo-top-gap: 20px;
    --logo-top-gap-home: 70px;
    --logo-height: 65px;
    --logo-main-height: 38vw;
    --side-margin: 0.671rem;
    --title-font-size: 36px;
    --title-font-weight: 400;

    --header-font-size: 36px;

    --font-size-small: 16px;

    --anchor-link-font-weight: 500;
    --anchor-link-font-size: 0.75rem;
    --anchor-link-line-height: 0.875rem;
    --side-drawer-width: 90%;
    --about-us-counter-div-height: 150px;
  }
}

/* 
::-webkit-scrollbar {
  width: 20px;
  background-color: #F5F5F5;
} */

@font-face {
  font-family: "Wensley";
  src: url("assets/fonts/WensleyDemo/WensleyDemo.ttf");
  font-weight: 300;
  font-style: normal;
}

.principal-card .card-body .card-title {
  /* color: #323131 !important; */
  text-align: center;
  font-family: "Cera Pro", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-transform: uppercase;
}

.principal-card .card-body img.mb-3 {
  width: 50px;
  height: 50px;
  margin: auto;
}

/* new css  start here */

.leadership-component {
  font-family: Arial, sans-serif;
}

.proven-leadership {
  padding: 4rem 0;
}

.proven-leadership h2,
.bg-light h2 {
  color: #003366;
  font-weight: bold;
}

.proven-leadership h2 {
  color: #003366;
  font-weight: bold;
  color: #203468;
  font-family: "Wensley";
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: 55px;
  letter-spacing: -5.5px;
}

.proven-leadership p {
  color: #3b3b3b;
  font-family: "Cera Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.bg-light {
  background-color: #eff9fd !important;
}

.text-primary {
  color: #33b9ea !important;
}

h3 {
  font-weight: bold;
}

h4 {
  color: #003366;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.card.team-leadership {
  background-color: transparent;
  border: none;
}

.team-leadership {
  position: relative;
  overflow: hidden;
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.8); */
  background-image: url("./assets/images//tl-bg.png");
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* align-items: center; */
  opacity: 0;
  transition: opacity 0.3s ease;
  /* padding: 82px 82px; */
  box-sizing: border-box;
  text-align: center;
}

.card-overlay p {
  margin: 5px 0;
  color: #fff;
  font-family: "Cera Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

.team-leadership:hover .card-overlay {
  opacity: 1;
}

.card-overlay h4 {
  margin: 10px 0;
  font-size: 24px;
}

.card-overlay p {
  margin: 5px 0;
}

.card-overlay img {
  margin-top: 10px;
  width: 30px;
  height: 30px;
}

.card-overlay h4 {
  margin: 0px 0;
  text-align: left;
  color: #fff;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -1.8px;
  text-transform: capitalize;
}

.team-leadership {
  position: relative;
  overflow: hidden;
  border-radius: 0px;
}

/* end css */

/* Font size classes */

/* Media query for screens between 1366px and 1440px */
@media (min-width: 1200px) and (max-width: 2600px) {

  .section-titles {
    margin-left: 5.3rem;
  }

  h2.section-title-value-propotion {
    color: #203468;
    font-family: 'Wensley', sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 57px;
    letter-spacing: -4.2px;
    margin-left: 4.6rem !important;
    padding-top: 20px;
  }

}

@media (min-width: 1440px) and (max-width: 2600px) {


  .section-titles {
    margin-left: 5rem;
  }

  .main-title-prospertiy {
    font-size: 80px !important;
    font-weight: 400 !important;
    color: #ffffff;
    margin-bottom: 1rem;
    font-family: "Wensley";
    line-height: 1.2;
    text-align: end;
  }

  .strategic-heading {
    letter-spacing: -5.5px !important;
    font-family: "Wensley";
    font-size: 70px !important;
    line-height: 80px !important;
  }

  h1.main-title.heading-title-invesment {
    font-size: 80px !important;
    letter-spacing: -7.8px !important;
  }

  .principal-sections h2 {
    font-size: 80px !important;
    letter-spacing: -7.8px !important;
  }

  .tabs-content-box {
    padding: 22px 5rem !important;
    margin-left: 24px !important;
  }

  h1.main-title.about-us-heading {
    font-size: 80px !important;
    letter-spacing: -8.1px !important;
  }

  .image-wrapper {
    position: relative;
    overflow: hidden;
    left: 68px !important;
    width: 639px !important;
  }

  .stragic-section-bg h2 {
    letter-spacing: -1.62px !important;
  }

  .App {
    overflow: auto;
  }

  .image-wrapper img {
    width: 100%;
    height: 500px !important;
    object-fit: cover;
  }

  .image-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    left: 12rem;
    font-family: "Wensley";
    top: 45% !important;
  }

  .main-title-prospertiy {
    letter-spacing: -7.8px !important;
  }

  .card-top-dss p {
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
    font-family: "Cera Pro";
    text-transform: none;
  }

  .team-leadership .img-fluid {
    height: 513px;
    object-fit: cover;
  }

  .proven-leadership p {
    color: #3b3b3b;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
    text-align: left;
  }

  .card-overlay p {
    margin: 5px 0;
    color: #fff;
    font-family: "Cera Pro" !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 23px !important;
    text-align: left;
  }

  .stragicFlex-box p {
    color: #3b3b3b;
    text-align: center;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .lead {
    font-family: "Cera Pro" !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 23px !important;
  }

  p.invest-mastery-description.aos-init.aos-animate {
    padding-left: 7rem;
  }

  .subtitle {
    font-family: "Cera Pro" !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px !important;
  }

  .section-title {
    font-size: 1rem;
    color: #6c757d;
    margin-bottom: 1rem;
    color: #333 !important;
    font-family: "Wensley", sans-serif !important;
    font-size: 27px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 14px !important;
    margin-top: 2rem !important;
  }

  .proven-leadership h2 {
    font-size: 80px !important;
    letter-spacing: -7.8px !important;
    line-height: 77px;
  }

  p.invest-mastery-description.aos-init.aos-animate {
    color: #fff;
    font-family: "Cera Pro";
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  p.description {
    color: #fff;
    text-align: right;
    font-family: "Cera Pro", sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
    padding-left: 47rem;
  }

  .card-counter {
    padding-top: 82px !important;
    padding-left: 82px !important;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* Your CSS rules here */
}

@media (min-width: 1366px) and (max-width: 1440px) {
  .strategic-heading {
    font-size: 80px !important;
    letter-spacing: -5.5px !important;
    line-height: 80px !important;
  }

  .image-wrapper {
    position: relative;
    overflow: hidden;
    left: 68px !important;
    width: 639px !important;
  }

  .stragic-section-bg h2 {
    letter-spacing: -1.62px !important;
  }

  .App {
    overflow: auto;
  }

  .image-wrapper img {
    width: 100%;
    height: 500px !important;
    object-fit: cover;
  }

  .image-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    left: 12rem !important;
    font-family: "Wensley";
    top: 50% !important;
  }

  .main-title-prospertiy {
    letter-spacing: -5.5px !important;
  }

  .card-top-dss p {
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 21px !important;
  }

  .team-leadership .img-fluid {
    height: 513px;
    object-fit: cover;
  }

  .proven-leadership p {
    color: #3b3b3b;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 21px !important;
    text-align: left;
  }

  .card-overlay p {
    margin: 5px 0;
    color: #fff;
    font-family: "Cera Pro" !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 23px !important;
    text-align: left;
  }

  .stragicFlex-box p {
    color: #3b3b3b;
    text-align: center;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .lead {
    font-family: "Cera Pro" !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 23px !important;
  }

  .subtitle {
    font-family: "Cera Pro" !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 23px !important;
  }

  .section-title {
    font-size: 1rem;
    color: #6c757d;
    margin-bottom: 1rem;
    color: #333 !important;
    font-family: "Wensley", sans-serif !important;
    font-size: 27px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 14px !important;
    margin-top: 2rem !important;
  }

  .proven-leadership h2 {
    font-size: 55px;
  }

  p.invest-mastery-description.aos-init.aos-animate {
    color: #fff;
    font-family: "Cera Pro";
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  p.description {
    color: #fff;
    text-align: right;
    font-family: "Cera Pro", sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
    padding-left: 47rem;
  }

  .card-counter {
    padding-top: 6rem !important;
    padding-left: 0px !important;
  }

  /* Your CSS rules here */
}

@media (min-width: 1200px) and (max-width: 4000px) {
  .header-logo {
    width: 236px !important;
    height: 86px;
  }

  .lg-none {
    display: none;
  }
}


@media (min-width: 2000px) and (max-width: 2560px) {

  .container {
    max-width: 94% !important;
  }

  .px-4.mx-auto.main-section-news {
    padding-left: 4.9rem !important;
    padding-right: 4.9rem !important;
  }

}



@media (min-width: 1600px) and (max-width: 1920px) {
  .container {
    max-width: 1420px !important;
  }

  .value-section {
    max-width: 91% !important;
  }

  .main-section-blog-detail {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

}

/* Media query for screens between 1499px and 1920px */
@media (min-width: 1499px) and (max-width: 1920px) {



  .px-4.mx-auto.main-section-news {
    padding-left: 3.7rem !important;
    padding-right: 3.7rem !important;
  }

  /* Your CSS rules here */

  .card-counter {
    padding-top: 9rem !important;
  }
}

.principal-card .card-body .card-title {
  /* color: #323131 !important; */
  text-align: center;
  font-family: "Cera Pro", sans-serif !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  text-transform: uppercase;
}

img.social-team-icon {
  width: 28px !important;
  margin-top: 1rem;
}

section.container.principal-sections {
  margin-top: 3rem;
  padding-bottom: 5rem;
}

.box {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 0px !important;
  background-color: #f9f9f9;
  transition: transform 0.2s;
}

.main-title {
  letter-spacing: -5.5px;
}

.contact-us-section {
  background-color: #f0f9fd;
  padding-top: 2rem;
}

h2.section-title.leadership-heading {
  padding-top: 5rem;
}

.border-dark,
.counter-div {
  border-color: #b7b7b7 !important;
}

.stragicFlex-box p {
  font-family: "Cera Pro";
}

.principal-card {
  background-color: white;
  border-radius: 0px;
  padding: 80px 20px;
  /* width: 375px; */
  height: 364px;
  flex-shrink: 0;
  box-shadow: none !important;
}

/* .paragraph-gap {
  text-transform: math-auto !important;
  line-height: 20px !important;
} */

.header-menu-icon {
  cursor: pointer;
}

.logo-gif {
  width: 60%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .logo-gif {
    width: 80%;
  }
}

.icon-link {
  cursor: pointer;
}

/* ravi css start */
@media (min-width: 200px) and (max-width: 400px) {

  .ml-\[86px\] {
    margin-left: 20px !important;
  }

  .ml-\[86px\] {
    margin-left: 14px;
  }

  span.highlight-value {
    color: #33B9EA;
    margin-left: 9px;
  }

  .about-us-content .main-title-about {
    font-size: 32px !important;
    color: #203468;
    margin-bottom: 1rem;
    font-family: "Wensley", sans-serif;
    line-height: 1.2;
    letter-spacing: -4px;
  }

  .br-none {
    display: none;
  }

  /* .App {
    overflow-x: hidden;
  } */



  .image-container-section .image-overlay h2 {
    font-size: 32px !important;
    letter-spacing: -4px;
    text-align: center;
  }

  .main-title-prospertiy {
    font-size: 32px;
    letter-spacing: -4px;
  }

  .image-container,
  .text-container {
    padding: 0rem !important;
  }

  .image-container-section .image-overlay {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: center;
    left: 6rem !important;
    font-family: "Wensley", sans-serif;
    top: 8rem !important;
    flex-wrap: nowrap;
  }

  .stragic-section-bg h1.display-4.mb-5.strategic-heading {
    text-align: left;
    color: #203468;
    font-family: "Wensley", sans-serif;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 42px !important;
    letter-spacing: -4px !important;
  }

  .about-us {
    padding: 1.5rem 0;
  }

  .investment-Leadership .heading-title-invesment {
    font-size: 32px !important;
    letter-spacing: -4px !important;
  }

  .teams-heading {
    font-size: 32px !important;
    letter-spacing: -4px !important;
    font-family: "Wensley";
  }

  .proven-leadership h2 {
    font-size: 32px !important;
    letter-spacing: -4px;
  }

  .contact-heading {
    font-size: 32px !important;
    letter-spacing: -4px !important;
  }

  .icons-social-media {
    display: flex;
    gap: 20px;
    margin-top: 19px;
    flex-direction: row;
    padding-bottom: 20px;
  }

  .inquiry-form-section {
    background-color: #ffffff;
    padding: 17px 20px !important;
    margin-bottom: 6rem;
    background: #fff;
    box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.05);
  }

  .form-header h2 {
    font-size: 32px !important;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
    letter-spacing: -4px;
    font-family: "Wensley";
  }

  .sidemenu-container {
    padding-top: 122px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    background-color: #EFF9FD;
    background-blend-mode: multiply;
    background-size: contain;
    background-repeat: no-repeat;
  }

}


@media (min-width: 200px) and (max-width: 900px) {
  ol.breadcrumb {
    font-size: 16px;
    font-family: 'Cera Pro';
  }


  nav.menu-breadcrumb-items.d-flex.align-items-center {
    margin-left: -6px !important;
  }

  .text-gray-500.date-p {
    color: #3B3B3B;
    font-family: "Cera Pro";
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: none;
  }

  .log-decription p {
    padding-bottom: 1.5rem;
    font-size: 16px !important;
  }

  h2.text-linkdin {
    font-size: 16px !important;
    font-family: 'Cera Pro' !important;
    border-bottom: 1px solid #b2afaf;
    padding-bottom: 13px;

  }

  p.text-gray-600.mb-4.line-clamp-3 {
    color: #3B3B3B;
    font-family: "Cera Pro";
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: math-auto;
  }

  .blog-date-boxes {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    gap: 22px !important;
  }

  .card.leadership-card {
    width: 22rem;
    text-align: start !important;
    background-color: transparent;
    border: none;
    padding-left: 20px !important;
  }


  .main-leadership {
    height: 625px !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: -225px;
  }

  .core-value-box p {
    color: #fff;
    font-family: "Cera Pro";
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px !important;
  }

  /* #logo {

    top: 0% !important;


  } */

  .section-title-value-propotion span {
    text-align: left;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .core-value-container {

    width: 95% !important;
    background-color: #fff;
    margin: auto;
    margin-bottom: 2rem !important;
  }

  .container-section-value {
    padding-bottom: 0rem !important;
  }

  h2.section-title-value-propotion {
    text-align: left !important;
  }

  .about-contents {
    overflow-x: hidden;
    width: 100%;
  }

  /* .website-contnet {
    overflow-x: hidden;
  } */
  .coming-soon {
    text-align: center !important;
  }

  html,
  body {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    scroll-behavior: smooth !important;
    height: 100%;
    width: 100%;
  }

  .moblie-home-footer {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px !important;
  }

  section.principal-sections.about-us-content-main {
    padding-left: 10px;
  }


  * {
    box-sizing: border-box;
  }

  .counter-div {

    width: 100%;
    padding: 0px !important;
  }

  .tabs-news {
    font-size: 13px !important;
  }

  img.w-full.blog-image-detail {
    height: auto !important;
    object-fit: cover;
  }


  .main-section-blog-detail {
    padding-left: 10px;
    padding-right: 10px;
  }

  .main-section-news {
    padding: 0px !important;
  }

  .text-4xl {
    font-size: 25px !important;
    line-height: 2.5rem;
    padding-top: 40px;
  }

  li.breadcrumb-item.breadcrumb-link.px-2.py-1.border-dashed.text-primary.active {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 103px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .section-titles {
    margin-left: 1rem !important;
  }

  h2.section-title-value-propotion {
    color: #203468;
    font-family: 'Wensley', sans-serif;
    font-size: 27px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 37px !important;
    letter-spacing: -1.2px !important;
    margin-left: 1rem !important;
    padding-top: 20px !important;
  }

  .boxes-values {
    background-color: #08132F !important;
    padding: 20px !important;
    padding-bottom: 49px !important;



  }


  img.icon-value {
    width: 55px;
    height: 55px;
  }

  .value-des p {
    color: #FFF;
    font-family: "Cera Pro";
    font-size: 16px !important;
    font-style: normal;
    font-weight: 300;
    line-height: 20px !important;
  }

  .about-value-prepotion h2 {
    color: #FFF;
    font-family: Wensley;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 34px !important;
    letter-spacing: -1.4px;
    text-transform: capitalize;
  }

  .about-value-prepotion {
    display: flex;
    color: white;
    margin-top: 2rem;
    gap: 25px;
    flex-direction: column !important;
  }

}

@media (min-width: 400px) and (max-width: 900px) {

  .section-titles {
    margin-left: 1rem !important;
  }

  h2.section-title-value-propotion {
    color: #203468;
    font-family: 'Wensley', sans-serif;
    font-size: 27px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 37px !important;
    letter-spacing: -1.2px !important;
    margin-left: 1rem !important;
    padding-top: 20px !important;
  }

  .App {
    overflow-x: hidden;
  }

  .contact-heading {
    font-size: 36px !important;
    letter-spacing: -4px !important;
  }

  .icons-social-media {
    display: flex;
    gap: 20px;
    margin-top: 19px;
    flex-direction: row;
    padding-bottom: 20px;
  }

  .stragic-section-bg h1.display-4.mb-5.strategic-heading {
    text-align: left;
    color: #203468;
    font-family: "Wensley", sans-serif;
    font-size: 36px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 42px !important;
    letter-spacing: -4px !important;
  }

  .proven-leadership h2 {
    font-size: 36px !important;
    letter-spacing: -4px;
  }

  .about-us-content .main-title-about {
    font-size: 36px !important;
    color: #203468;
    margin-bottom: 1rem;
    font-family: "Wensley", sans-serif;
    line-height: 1.2;
    letter-spacing: -4px;
  }

  .br-none {
    display: none;
  }

  .image-container-section .image-overlay h2 {
    font-size: 36px !important;
    letter-spacing: -4px;
    text-align: center;
  }

  .image-container-section .image-overlay {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: center;
    left: 6rem !important;
    font-family: "Wensley", sans-serif;
    top: 8rem !important;
    flex-wrap: nowrap;
  }

  .principal-sections h2 {
    color: #203468;
    font-family: "Wensley", sans-serif;
    font-size: 36px !important;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 20px;
    line-height: 65px;
    letter-spacing: -4px !important;
  }

  section.principal-sections.about-us-section {
    padding-right: 23px !important;
    padding-left: 23px !important;
  }

  .investment-Leadership .heading-title-invesment {
    font-size: 36px !important;
    letter-spacing: -4px !important;
  }

  .teams-heading {
    font-size: 36px !important;
    letter-spacing: -4px !important;
    font-family: "Wensley";
  }

  .inquiry-form-section {
    background-color: #ffffff;
    padding: 17px 20px !important;
    margin-bottom: 6rem;
    background: #fff;
    box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.05);
  }

  .form-header h2 {
    font-size: 36px !important;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
    letter-spacing: -4px;
    font-family: "Wensley";
  }
}

footer.fixed.bottom-0.left-0.right-0.flex.justify-center.pb-4 {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  position: absolute;
}

button.p-2.rounded-full.bg-blue-500.text-white.hover\:bg-blue-600.transition-colors.mb-10.button-footer-arrow {
  position: relative;
  z-index: 999999999;
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 185 234) !important;
}

/* end css  */

.tab-corevalue-list {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;

  gap: 20px !important;
  margin-bottom: 8px !important;
  color: white !important;
  border: 1px solid rgba(32, 52, 104, 0.5) !important;
  background: #fff;
  margin-left: 0rem !important;
  color: #3b3b3b !important;
  font-family: "Cera Pro" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  padding: 12px 12px !important;
  max-width: 360px !important;
  min-width: 80px !important;
  min-height: 71px !important;
  width: 100% !important;
}

.tab-corevalue-list.MuiTab-root.Mui-selected {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;

  gap: 20px !important;
  margin-bottom: 8px !important;
  color: white !important;
  border: 1px solid rgba(32, 52, 104, 0.5);
  background: #203569;
  margin-left: 0rem !important;
  font-family: "Cera Pro" !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  width: 100% !important;
  padding: 12px 12px !important;
  max-width: 360px !important;
  min-width: 80px !important;
  min-height: 71px !important;
}

.corevalue-content-box {
  /* background: #203468; */
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.tabs-corevalue {
  margin: 20px;
}

.core-value-container {
  margin-bottom: 4rem;
}

.core-value-box h5 {
  color: white;
}

.tab-corevalue-list.Mui-selected svg path {
  fill: white !important;
  /* stroke: white !important; */
}

.tab-icon-core-value svg path {
  fill: white !important;
}

.tab-icon-core-value svg {
  width: 80px;
  height: 80px;
  margin-bottom: 2rem !important;
}

.tab-corevalue-list svg {
  width: 30px;
  height: 30px;
}

.MuiTabs-indicator {
  display: none;
}

.box-corevalue-conetent-des {
  width: 90%;
  margin: auto;
}

.core-value-box h5 {
  color: #fff;
  font-family: Wensley !important;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -2.16px;
}

.core-value-box p {
  color: #fff;
  font-family: "Cera Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.core-value-box {
  background: #203468;
  height: 390px;
  display: flex;
  align-items: center;
}

.tabs-corevalue.MuiTabs-scroller {
  margin-right: 76px !important;
}



/* span.tab-lebel-text {
  position: relative;
  display: flex;
  justify-content: flex-start;
  text-align: start;
  width: 320px;
  text-transform: capitalize;
  white-space: none !important;
  top: -3px;
} */

/* Core Value Container */
.core-value-container {
  width: 100%;
  background-color: #ffffff;
}

/* Tabs Container */
.tabs-corevalue {
  min-width: 200px;
}

/* Equal Width Tabs */
.tabs-corevalue .MuiTabs-flexContainer {
  display: flex;
}

.tabs-corevalue .MuiTab-root {
  flex: 1;
  max-width: none;
  min-width: 0;
  padding: 12px 16px;
}

/* Tab Label Text */
.tab-lebel-text {
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabs-corevalue .MuiTab-root {
  flex: 1 1;
  max-width: none;
  min-width: 0;
  padding: 12px 16px;
  width: 100% !important;
}

/* Core Value Content Box */
.corevalue-content-box {
  flex-grow: 1;
}

.core-value-box {
  padding: 0px;
}

/* Tab Icon */

.tab-icon-core-value svg {
  width: 48px;
  height: 48px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .tabs-corevalue {
    min-width: auto;
  }

  .popup-close-box h2 {
    font-family: "Wensley" !important;
    font-size: 28px !important;
    font-weight: 700;
    color: #33b9ea !important;
  }

  .popup-close-box .MuiPaper-root {
    max-width: 680px !important;
    padding: 20px 22px !important;
  }

  .core-value-box {
    align-items: center;
    background: #11255900 !important;
    display: flex;
    height: 100% !important;
    margin-bottom: 0rem !important;
    flex-direction: column;
  }

  .core-value-content-box {
    background-color: #112559 !important;
    width: 100%;
    margin-bottom: 1rem !important;
  }

  .tabs-corevalue {
    margin-left: 0px !important;
  }

  .box-corevalue-conetent-des {
    margin: auto;
    width: 100% !important;
  }

  .corevalue-content-box {
    align-items: center;
    color: #fff;
    display: flex;
    justify-content: center;
    margin: 0;
    margin-top: 0px !important;
    padding: 0;
    margin-bottom: 0rem !important;
  }

  span.tab-lebel-text {
    position: relative;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    width: auto !important;
    text-transform: capitalize;
    white-space: none !important;
    top: -3px;
  }

  .core-value-box {
    align-items: center;
    background: #203468;
    display: flex;
    height: 100% !important;
    margin-bottom: 3rem;
  }

  .tabs-corevalue .MuiTabs-flexContainer {
    flex-direction: row;
    overflow-x: auto;
  }

  .tabs-corevalue .MuiTab-root {
    flex: 0 0 auto;
    min-width: 120px;
  }

  .core-value-box {
    padding: 0px;
  }
}

/* Accessibility */
.tabs-corevalue .MuiTab-root:focus-visible {
  outline: 2px solid #03a9f4;
  outline-offset: -2px;
}

/* Selected Tab Styles */
.tabs-corevalue .MuiTab-root.Mui-selected {}

.tabs-corevalue .MuiTab-root.Mui-selected .tab-lebel-text {
  font-size: 13px;
}

.tabs-corevalue {
  min-width: fit-content !important;
  margin-left: 0rem !important;
}

.corevalue-content-box {
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0px;
  margin-top: -19px !important;
}

.close-btn-continer {
  font-family: "Cera Pro";
  justify-content: center !important;
}

.popup-close-box {
  font-family: "Cera Pro";
}

.popup-close-box .btn-close-success {
  color: #33b9ea !important;
}

.home-heading-leadership {
  line-height: 60px;
}


.conatiner-section {
  background-color: #f3fbfe;
  padding: 159px 33px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs-blogs .MuiTabs-flexContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}


p.text-gray-600.mb-4.line-clamp-3 {
  color: #3B3B3B;
  font-family: "Cera Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: math-auto;
}

button.btn-blogs {
  color: #203468;
  font-family: "Cera Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
}

h3.blog-heading {
  color: #203468;
  font-family: Wensley;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -1.4px;
  text-transform: capitalize;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.card-blogs:hover .btn-blogs {
  --tw-bg-opacity: 1;
  background-color: #33B9EA;
  color: white;
  border: #33B9EA !important;
}

.card-blogs:hover {
  background-color: #EFF9FD !important;
}

.blog-detail-heading {
  color: #203468;
  font-family: 'Wensley';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: -1.8px;
  text-transform: capitalize;
}


.leading-relaxed {
  color: #3B3B3B;
  font-family: "Cera Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: none;
}


.why-blog-detail-heading {
  color: #3B3B3B;
  font-family: "Cera Pro";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: capitalize;
}


.text-gray-500.date-p {
  color: #3B3B3B;
  font-family: "Cera Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;
}

ol.breadcrumb {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  font-size: 16px;
  gap: 0px;
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: #33B9EA;
}

.tracking-wide {
  letter-spacing: 0.025em;
  color: #333;
  font-family: Wensley;
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.value-section {

  background-color: #17264E !important;
}

.about-value-prepotion {
  display: flex;
  color: white;
  margin-top: 2rem;
  gap: 25px;
}

.about-value-prepotion h2 {
  color: #FFF;
  font-family: Wensley;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: -1.4px;
  text-transform: capitalize;
}

h2.section-title-value-propotion {
  color: #203468;
  font-family: 'Wensley', sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: -4.2px;
  margin-left: 4rem;
  padding-top: 20px;
}

.section-title-value-propotion span {}

span.highlight-value {
  color: #33B9EA;
}

.card-blogs {

  cursor: pointer;
}

.value-des p {
  color: #FFF;
  font-family: "Cera Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  padding-top: 8px;
  line-height: 18px;
}

.main-section-blog-detail {

  margin-bottom: 2rem;

}


.tracking-wide {

  letter-spacing: -2.5px !important;
}

.card-blogs {
  cursor: pointer;
  box-shadow: 0px 4.228px 84.56px 0px rgba(0, 0, 0, 0.05) !important;
}

.bg-white.overflow-hidden.shadow-md {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4.228px 84.56px 0px !important;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-family: 'Cera Pro';
  font-size: 14px;
  line-height: 20px;
}

ol.breadcrumb {

  font-size: 13px;

}

.button-pagination {
  font-family: 'Cera Pro';
  font-size: 15px;
  font-weight: 500;
}


.log-decription figure.image {
  width: 822px !important;
  height: auto;
  margin-top: 0rem !important;
  position: static !important;
  background: none !important;
}

.log-decription img {
  max-width: 100%;
  height: auto;
  display: block;
  /* Ensures it doesn't overflow inline */
}



.blog-news-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.log-decription p {
  padding-bottom: 1.5rem;
}

.date-p {
  display: flex;
  gap: 26px;
}



.linkdeen-box img {
  margin-top: 1rem;
  width: 30px;
}

.hover\:bg-sky-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(52 185 234) !important;
  color: white !important;
}

h2.text-linkdin {
  font-size: 14px;

  font-family: 'Cera Pro' !important;
}

nav.menu-breadcrumb-items.d-flex.align-items-center {
  margin-left: -4px;
}

.date-box {
  display: flex !important;
  gap: 20px !important;
  /* color: black !important; */
  font-family: 'Cera Pro' !important;
  font-weight: 400 !important;
}

h2.text-linkdin {
  font-size: 14px;
  font-family: 'Cera Pro' !important;
  border-bottom: 1px solid #b2afaf;
  padding-bottom: 13px;
  width: 352px;
}

/* .sticky-sidebar {
  position: fixed;
  top: 2rem;
  width: calc(33.333% - 1.5rem); 
  transition: 1s;
} */

/* @media (max-width: 1023px) {
  .sticky-sidebar {
    position: static;
    width: 100%;
  }
} */


/* Custom Colors */