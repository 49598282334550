.leadership-component {
  background-image: url("../../../src/assets/homepage_bg.png");
  background-size: cover; /* Adjust as needed */
  background-repeat: no-repeat; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
}

.mobile-card-overlay {
  display: none;
}
.leadership-title-main {
  margin-left: 86px;
}

@media (max-width: 767px) {
  .mobile-card-overlay {
    display: block;
    margin: 2px;
  }
  .leadership-title-main {
    margin-left: 12px;
  }
}
