.terms-section {
  background-color: #f8f9fa;
  background-image: url("../../assets/images//contact-us-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
