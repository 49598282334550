.counter-div {
  width: 100%;
  height: var(--about-us-counter-div-height);
  border-color: #b7b7b7;
  display: flex;
  flex-direction: column;
  gap: 22px;
  align-items: center;
  justify-content: center;
}

.counter-title {
  font-family: "Cera Pro";
  font-style: normal;
  font-weight: var(--title-font-weight);
  font-size: var(--font-size-small);
  line-height: 22px;
  text-transform: capitalize;
  color: #203468;
  display: block;
  text-align: center;
}

.counter-value {
  font-family: "Wensley";
  font-style: normal;
  font-weight: var(--title-font-weight);
  font-size: 29px;
  text-transform: capitalize;
  display: block;
  color: #33b9ea;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: center;
  line-height: 1.2;
}

.counter-value span {
  display: inline;
}
