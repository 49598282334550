@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img.socail-icon {
  width: 18px;
  height: 18px;
}

@media screen and (max-width: 768px) {
.terms-section.privacy-section {
  background-color: #f8f9fa;
  background-size: cover;
  background-repeat: no-repeat;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: flex-start!important;
  text-align: center;
  padding-top: 130px!important;
}
}
