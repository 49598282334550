.contact-us-section {
  background-color: #f8f9fa;
  background-image: url("../../assets/images//contact-us-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-info p {
  margin: 0;
  line-height: 1.5;
}

.social-media a {
  margin-right: 10px;
  font-size: 20px;
  color: #007bff;
}

.social-media a:hover {
  color: #0056b3;
}

.inquiry-form-section {
  background-color: #ffffff;
}

.form-header h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
}

.form-header .text-primary {
  color: #33b9ea;
}

.form-control {
  background-color: #f7f7f7;
  border: none;
  border-radius: 0;
  height: 50px;
  font-size: 16px;
}

.form-control::placeholder {
  color: #999999;
}

.form-control:focus {
  box-shadow: none;
  border-color: #00aaff;
}

.form-button {
  width: 150px;
  height: 50px;
  font-size: 16px;
  font-weight: 700;

  color: #203468;
  font-family: "Cera Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 87.5% */
  text-transform: uppercase;
  border-radius: 0;
  border: 1px solid #d0d0d0;
}

.form-button:hover {
  background-color: #34b9ea;
  color: #ffffff;
  border: none;
}

textarea.form-control {
  min-height: calc(
    8.5em + 0.75rem + calc(var(--bs-border-width) * 2)
  ) !important;
}

.contact-us-section {
  background-color: #f0f9fd;
}

.inquiry-form-section {
  background-color: #ffffff;
  padding: 35px 35px !important;
  margin-bottom: 7rem;
  background: #fff;
  box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.05);
}

.icons-social-media {
  display: flex;
  gap: 20px;
  margin-top: 19px;
}

.contact-info p {
  margin: 0;
  line-height: 1.5;
  color: #3b3b3b;
  font-family: "Cera Pro";
  font-size: 14.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  gap: 19px;
  margin-bottom: 1.5rem;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: #f7f7f7 !important;
  border-color: #f7f7f7;
  outline: 0;
  box-shadow: none !important;
}

.form-header h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  letter-spacing: -1.2px;
  font-family: "Wensley";
}

.form-control {
  background-color: #f7f7f7;
  border: none;
  border-radius: 0;
  height: 50px;
  font-size: 16px;
  color: #3b3b3b;
  font-family: "Cera Pro";
  font-size: 14.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .inquiry-form-section {
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .terms-section {
    background-color: #f8f9fa;
    background-size: cover;
    background-repeat: no-repeat;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: flex-start!important;
    text-align: center;
    padding-top: 130px!important;
  }


  .privacy-section {
    background-color: #f8f9fa;
    background-size: cover;
    background-repeat: no-repeat;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: flex-start!important;
    text-align: center;
    padding-top: 130px!important;
  }


  }
